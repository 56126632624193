/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
// import { Link } from 'gatsby';
import { nanoid } from 'nanoid';

export const customerNumber = '20,000';

export const tryDemoLink = 'https://web.ruttl.com/share/demo/';

export const TestimonialsContent = [
  {
    img: '/assets/img/jay-p.png',
    authorName: 'Jay P',
    authorDesignation: 'Technical Consultant',
    description: (
      <>
        As an agency, we needed a platform to use with our client that was easy
        to access and use. ruttl fits the bill perfectly! It allows users to
        review not only single web pages, but the entire website AND even static
        images. It is an easy-to-use and fantastic website review app that I use
        with my agency every day. The best part about it is the support and
        ongoing effort to make the product better every week.
      </>
    ),
  },

  {
    img: '/assets/img/christiano.png',
    authorName: 'Christiano Hans',
    authorDesignation: 'Founder at Hans Republic',
    description: (
      <>
        This tool is fast for collecting, managing, and previewing design
        feedback on live websites. I can easily create mockups live from the
        browser and collaborate with the client and team members. It is user
        friendly, gives real-time update, has a versioning feature, and includes
        white label as well.
      </>
    ),
  },
  {
    img: '/assets/img/avatar-4.png',
    authorName: 'Sumol',
    authorDesignation: '',
    description: (
      <>
        Really good feedback system, very well thought out. No need to bother
        with screenshots, and the platform is easy to use. I recommend it to all
        those who often have to modify sites for their client
      </>
    ),
  },
  {
    img: '/assets/img/sales-team.png',
    authorName: 'Perses',
    authorDesignation: '',
    description: (
      <>
        Ruttl is a tool made by designers for designers. It&apos;s more than
        just a text editor. The app allows you to edit your text with just a few
        taps, and it&apos;s aptly put together to make the process as smooth as
        possible. I used to do this using screenshots and videos, but Ruttl has
        made it so much easier.
      </>
    ),
  },
  {
    img: '/assets/img/project-manager.png',
    authorName: 'DoCreative',
    authorDesignation: '',
    description: (
      <>
        Ruttl helps me get feedback from clients on designs of websites, logos,
        etc. It saves a lot of time. The client can look at the design at their
        ease and comment if they want. I can respond and reflect the requested
        changes. It keeps the revisions in order, and the idea and usability is
        top notch.
      </>
    ),
  },
].map(({ img, authorName, authorDesignation, description }, id) => ({
  img,
  authorName,
  authorDesignation,
  description,
  id,
}));

export const TestimonialsContentGoogle = [
  {
    img: '/assets/img/nitin-kathayat.jpeg',
    authorName: 'Nitin Kathayat',
    authorDesignation: 'All things UX at Razorpod',
    description:
      'Does all the things expected: Saves time, intuitive throughout, keeps evolving. Ruttl replaces  the use of multiple legacy tools, gets everyone on the same page.',
  },
  {
    img: '/assets/img/felix.png',
    authorName: 'Felix Thompson',
    authorDesignation: 'Web developer at Talentiz',
    description: (
      <>
        Instead of going back and forth with the client talking about what
        changes, trying to understand each other, you will receive a email
        notification that you have new edits to make. The best thing of them
        all? You can do it live and direct! No waiting time, you just log in the
        application and see everything you need to do with a perfect visual.
        Client don&apos;t even have to login!
      </>
    ),
  },
  {
    img: '/assets/img/francisco-oilden.jpeg',
    authorName: 'Francisco Oliden',
    authorDesignation: 'Client relationship manager at Agencia Eremo',
    description:
      'I now spend less time trying to understand all the changes my clients want, passing on those comments and it’s super easy for my clients to share visual feedback.',
  },
  {
    img: '/assets/img/amit-arora.jpeg',
    authorName: 'Amit Arora',
    authorDesignation: 'UI/UX Designer at MikeLegal',
    description:
      'Ruttl, helped me cut down meetings with front-end devs to explain them all the issues on the website and made it simple to track changes as well as keep track of them. 10/10 would recommend.',
  },

  {
    img: '/assets/img/melroy-dickson.jpeg',
    authorName: 'Melroy Dickson',
    authorDesignation: 'Digital Strategist at Cube Reach Technologies',
    description: (
      <>
        Just begun using Ruttl. It&apos;s an awesome feedback tool for live
        websites that lets clients give their feedback visually. Life is so much
        easier with a live tool like this to communicate with clients.
      </>
    ),
  },
  {
    img: '/assets/img/joe-fletcher.jpeg',
    authorName: 'Joe Fletcher',
    authorDesignation: 'Founder of Fletcher Digital',
    description: (
      <>
        I&apos;ve used several point &amp; click feedback tools for website
        collaboration with clients. ruttl is miles ahead in terms of features,
        ease of use, speed, and product innovation. My new go-to for client
        feedback!
      </>
    ),
  },
  {
    img: '/assets/img/ludovic_clain.png',
    authorName: 'Ludovic Clain',
    authorDesignation: 'Founder of PrakSite',
    description: (
      <>
        Ruttl is one of the essential online tools for any web agency that calls
        itself modern, indeed nothing is more frustrating for a client today to
        send several screenshots, annotations and endless discussions by email
        to be understood. Ruttl solves all this brilliantly, the client can
        transform the design, personalize the text, comment on parts and get a
        better idea of the rendering even before the work is done. Fun and
        practical, I truly recommend ruttl.
      </>
    ),
  },
  {
    img: '/assets/img/abhishek-sharma.jpeg',
    authorName: 'Abhishek Sharma',
    authorDesignation:
      'VP (Strategy and Business) at Quantum Phinance Consulting Private Limited',
    description: (
      <>
        Reviewing &amp; monitoring websites has always been a pain area.
        Discussions &amp; feedbacks were spread across various apps. Ruttl has
        fast-tracked our work significantly.
      </>
    ),
  },
  {
    img: '/assets/img/craig-hooper.jpeg',
    authorName: 'Craig Hooper',
    authorDesignation: 'Senior UX/UI Designer at Banfield',
    description: (
      <>
        We have been searching for the right application to handle our design
        &amp; development reviews for a long time. We tested Pastel, but it has
        critical flaws that made it unusable for us. Others we looked at, like
        Userback, were great—but WAY more than we needed with respect to
        features. ruttl just hits the right spot for agency owners like us
      </>
    ),
  },
  {
    img: '/assets/img/developer.png',
    authorName: 'Mahendra Choudhary',
    authorDesignation: 'Independent Digital Marketing Consultant',
    description: (
      <>
        ruttl streamlined the project workflow and saved time by removing the
        unnecessary back and forth email, having everything documented and avoid
        confusion.
      </>
    ),
  },
  {
    img: '/assets/img/designer.png',
    authorName: 'Sanket',
    authorDesignation: 'Web Designer at Biowebex',
    description: (
      <>
        As a website designer, I really like ruttl. It reduces the website
        review cycles between my internal team and the client. All the best for
        the great journey of ruttl, looking forward!
      </>
    ),
  },
].map(({ img, authorName, authorDesignation, description }, id) => ({
  img,
  authorName,
  authorDesignation,
  description,
  id,
}));

export const brandLogos = [
  {
    name: 'Adobe logo',
    image: '/assets/img/logo/adobe-black.svg',
    width: '122',
    height: '32',
  },
  {
    name: 'Atlassian logo',
    image: '/assets/img/logo/atlassian-black.svg',
    width: '144',
    height: '36',
  },
  {
    name: 'Lottie files logo',
    image: '/assets/img/logo/lottiefiles-black.svg',
    width: '144',
    height: '30',
  },
  {
    name: 'squadstack logo',
    image: '/assets/img/logo/squadstack-black.png',
    width: '200',
    height: '35',
  },
  {
    name: 'razorpay logo',
    image: '/assets/img/logo/razorpay-black.svg',
    width: '144',
    height: '31',
  },
  {
    name: 'Gartner logo',
    image: '/assets/img/logo/gartner-black.svg',
    width: '124',
    height: '28',
  },
  {
    name: 'Nintendo logo',
    image: '/assets/img/logo/nintendo-black.svg',
    width: '144',
    height: '36',
  },
].map(({ name, image, width = 'auto', height = 'auto' }, id) => ({
  name,
  image,
  id,
  width,
  height,
}));
export const brandLogosBugTrack = [
  {
    name: 'Lottie files logo',
    image: '/assets/img/logo/lottiefiles-purple.svg',
    width: '144',
    height: '30',
  },
  {
    name: 'squadstack logo',
    image: '/assets/img/logo/squadstack-purple.png',
    width: '200',
    height: '35',
  },
  {
    name: 'razorpay logo',
    image: '/assets/img/logo/razorpay-purple.svg',
    width: '144',
    height: '31',
  },
  {
    name: 'Adobe logo',
    image: '/assets/img/logo/adobe-purple.svg',
    width: '122',
    height: '32',
  },
  {
    name: 'Gartner logo',
    image: '/assets/img/logo/gartner-purple.svg',
    width: '124',
    height: '28',
  },
  {
    name: 'Atlassian logo',
    image: '/assets/img/logo/atlassian-purple.svg',
    width: '172',
    height: '22',
  },
].map(({ name, image, width = 'auto', height = 'auto' }, id) => ({
  name,
  image,
  id,
  width,
  height,
}));

// export const faqData = [
//   {
//     q: 'How to use ruttl? ',
//     a: 'Sign up, then create a project. Paste your website URL and add it to this project. Share the project with your team by adding their email addresses. Once the page loads on ruttl, it is in comment mode by default. Click to add comments, tag other team members in comments using ‘@’, resolve or delete comments. Switch to edit mode to move elements, replace images, edit text, change background colours, modify opacities, etc. These edits can be resolved once done, thereby speeding up your web review process.',
//   },
//   {
//     q: 'Is ruttl really a free feedback tool?',
//     a: (
//       <>
//         Yes, ruttl is really free to use! Your first project is on us. You can
//         invite your team members to join these projects and start collaborating
//         together to review websites. To give edit access to these members,
//         please visit our
//         <Link to="/pricing/">&nbsp;Pricing page</Link>.
//       </>
//     ),
//   },
//   {
//     q: 'Do clients need to have an account on ruttl to view projects?',
//     a: 'Absolutely not! Once your project is ready to share, simply click on the share button inside the project page. In the new screen, click on the ‘Link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get the client feedback on the project.',
//   },

//   {
//     q: 'How many members can I add to a project?',
//     a: (
//       <>
//         Answer: In the free version, you have the ability to add up to 5
//         members on a project. However, when you subscribe to any of our
//         plans, you get to add unlimited members.
//         <Link to="/pricing/">&nbsp;Click here&nbsp;</Link>
//         to see all our plans.
//       </>
//     ),
//   },
//   {
//     q: 'Are there any integrations available in ruttl?',
//     a: (
//       <>
//         Currently we have integrated Slack, Trello, Zapier, asana and Unsplash.
//         <Link to="/integrations/">&nbsp;Click here&nbsp;</Link>
//         to know more about our integrations.
//       </>
//     ),
//   },

//   {
//     q: 'Is there a public roadmap available?',
//     a: (
//       <>
//         Yes, we have our public roadmap. Here’s the
//         <a
//           href="https://trello.com/b/D9T51EZX"
//           target="_blank"
//           rel="noopener noreferrer">
//           &nbsp;link&nbsp;
//         </a>
//         to the same.
//       </>
//     ),
//   },
//   {
//     q: 'Can developers access website code after design changes are executed?',
//     a: 'After you use the inspect feature to edit and make required changes to the website design, your developers can simply copy the entire altered code under the CSS tab in the Activities Panel. This can then be pasted directly into the code of live website files.',
//   },
//   {
//     q: 'Why isn’t my website loading properly in the project panel?',
//     a: (
//       <>
//         Although this issue rarely occurs, it also depends on the tech stack of
//         the website you are trying to review. Currently, we are supporting
//         static responsive HTML CSS websites. However, we are soon planning to
//         push an update to support dynamic web applications based on technologies
//         such as React, Angular and Vue with login pages as well for feedback
//         collection. Please send your issues to us at
//         <a
//           href="mailto:support@ruttl.com"
//           target="_blank"
//           rel="noopener noreferrer">
//           &nbsp;support@ruttl.com
//         </a>
//       </>
//     ),
//   },
//   {
//     q: 'Which websites does ruttl work with?',
//     a: 'ruttl works with static, WordPress, Vue.js, and React sites for collaborating on visual website feedback. In fact, you can use ruttl to work on pretty much every website. Just paste the website URL and get started!',
//   },
//   // {
//   //   q:
//   //     'Can I review static webpage design images with my team on ruttl?',
//   //   a:
//   //     'Absolutely. Simply upload the image, and your team can instantly start leaving comments. The best part - even clients can leave comments - without the hassles of login!',
//   // },
//   {
//     q: 'Does ruttl support task management?',
//     a: (
//       <>
//         No, ruttl does not support task management. If you’re looking for the
//         same, we would recommend checking out Brutask. It is a simple online
//         to-do list for individuals and small teams. Add, prioritize and time box
//         your tasks to help improve your productivity.
//         <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
//           &nbsp;Click here&nbsp;
//         </a>
//         to know more about Brutask and give it a try.
//       </>
//     ),
//   },

//   {
//     q: 'I accidentally deleted my project! What should I do?',
//     a: 'We’ve got your back! Any deleted project gets directly archived inside your ruttl account. In order to retrieve it, visit your project dashboard. On the top left corner, click on active projects and switch it to archived projects. Simply hover over your deleted project and click on ‘Restore project’.',
//   },
//   {
//     q: 'How do I upgrade my ruttl account? ',
//     a: (
//       <>
//         In the top right menu on ruttl’s main dashboard, click on the ‘
//         <a
//           href="https://web.ruttl.com/billing"
//           target="_blank"
//           rel="noopener noreferrer">
//           My Plan
//         </a>
//         ’ section. Here you can click on the Upgrade button, select the plan in
//         monthly/annual mode that best suits you and your team and make the
//         payment. Your account will be upgraded once we successfully receive your
//         payment.
//       </>
//     ),
//   },
//   // {
//   //   q: 'How can I pay for my ruttl plan? ',
//   //   a:
//   //     'Currently, paying for your ruttl plan is easy - just follow the steps in the above FAQ, and your subscription will be active. With the future updates, our Indian users will also be able to pay through UPI or Netbanking.',
//   // },
//   {
//     q: 'Can I switch between plans before they end?',
//     a: 'Yes, it’s possible to switch but you can only upgrade your plan. Just select the plan, make the payment and your plan should be upgraded once we receive the payment!',
//   },
//   {
//     q: 'Will I get recommendations on my website’s look and feel?',
//     a: (
//       <>
//         ruttl will not be able to recommend anything about your website’s look
//         and feel. We would suggest you to please contact
//         <a href="https://brucira.com" target="_blank" rel="noopener noreferrer">
//           &nbsp;Brucira&nbsp;
//         </a>
//         by mailing us at
//         <a
//           href="mailto:hello@brucira.com."
//           target="_blank"
//           rel="noopener noreferrer">
//           &nbsp;hello@brucira.com.&nbsp;
//         </a>
//       </>
//     ),
//   },
//   {
//     q: 'I only want my clients to review, not edit the website design — is this possible?',
//     a: 'Of course! You can choose to share the guest link with your clients, and then they won’t be able to make any edits on the website design. This way, you can use our edit mode internally only with the ones whom you have added via email address and given edit access.',
//   },
//   // {
//   //   q: 'Does ruttl have a bug bounty program?',
//   //   a:
//   //     'We currently do not have a bug bounty program in place.',
//   // },
// ].map(({ q, a }) => ({
//   q,
//   a,
//   uuid: nanoid(),
// }));
export const testimonialFirstSection = [
  {
    tag: 'Agency Owners',
    content: `ruttl is miles ahead in terms of features, ease of use, speed, and product innovation! It’s my go-to for collecting client feedback.`,
    img: '/assets/img/joe-fletcher.png',
    author: 'Joe Fletcher',
    designation: 'Founder of Fletcher Digital',
    id: 10,
    videoAuthor: 'Afton Negrea',
    videoAuthorDesignation: 'Digital Business Strategist, Aftonnegrea.com',
    videoAuthorImg: '/assets/img/afton-negrea.jpg',
    videoUrl: 'https://www.youtube.com/embed/AgfXMw-RQMY',
    videoTitle: 'ruttl video',
    videoPreview: '/assets/video/customer-success-story-afton.mp4',
  },

  {
    tag: 'Designers & Developers',
    content: `ruttl cut down my meetings with front-end devs, to explain to them all the issues on the website and made it simple to make changes as well as keep track of them. 10/10!`,
    img: '/assets/img/amit-arora.jpg',
    author: 'Amit Arora',
    designation: 'UI/UX Designer at MikeLegal',
    id: 11,
    videoAuthor: 'John Bendever Jr',
    videoAuthorDesignation: 'Creative Director at Local Marketing Pros',
    videoAuthorImg: '/assets/img/john-bendever-jr.jpg',
    videoUrl: 'https://www.youtube.com/embed/CUqvFBtmBn4',
    videoTitle: "How This Agency Used Ruttl To Speed Up It's Feedback Process",
    videoPreview: '/assets/video/customer-success-story-john.mp4',
  },
  {
    tag: 'Product Companies',
    content: `Our team loved ruttl so much that we’re extending the license for a year beyond the event, to test it with other programs!`,
    img: '/assets/img/avatar-1.svg',
    author: 'Joshua Shepherd',
    designation: 'Sr. Marketing Manager at Atlassian',
    id: 12,
    videoAuthor: 'Dan Peres',
    videoAuthorDesignation: 'Founder of Ceran Technologies',
    videoAuthorImg: '/assets/img/dan-peres.jpg',
    videoUrl: 'https://www.youtube.com/embed/OHtRCDb9vUk',
    videoTitle: 'How Dan Used ruttl To Reduce Miscommunication With Clients',
    videoPreview: '/assets/video/customer-success-story-dan.mp4',
  },
];

export const Testimonial = [
  {
    tag: 'Agency Owners',
    content: `I’m able to make edits to the code directly from the platform- it speeds up your projects by allowing clients to review real time, and note changes. If you’re a web designer or developer, it will definitely speed up your work.`,
    img: '/assets/img/kanesha-harper.jpg',
    author: 'Kanesha Harper',
    designation: 'Founder at The Arch Effect',
  },
  {
    tag: 'Agency Owners',
    content: `It is an essential tool for any web agency- clients can just comment and get an idea of the rendering even before the work is done!`,
    img: '/assets/img/ludovic-clain.png',
    author: 'Ludovic Clain',
    designation: 'Founder of PrakSite',
  },
  {
    tag: 'Agency Owners',
    content: `ruttl has been a blessing because it simplified our feedback process, no long emails just to figure out what clients are trying to talk about!`,
    img: '/assets/img/laurence-anthony.jpg',
    author: 'Laurence Anthony',
    designation: 'Director of Pixallus',
  },
  {
    tag: 'Designers & Developers',
    content: `Life-changer- it saves me so much time! Clients don't need to login to leave feedback. Do yourself a favor and get the tool. It will basically pay for itself with just one client!`,
    img: '/assets/img/felix-thompson.jpeg',
    author: 'Felix Thompson',
    designation: 'Creator at Talentiz',
  },
  {
    tag: 'Designers & Developers',
    content: `Powerful, easy, precious. Some small issues for me, but globally more than good! It will probably become a daily tool for me.`,
    img: '/assets/img/francois-jung.jpeg',
    author: 'Francois Jung',
    designation: 'Director at synAct',
  },
  {
    tag: 'Product Companies',
    content: `I now spend less time trying to understand all the changes my clients want, passing on those comments and it’s super easy for my clients to share visual feedback.`,
    img: '/assets/img/francisco-oliden.jpg',
    author: 'Francisco Oliden',
    designation: 'Client Relationship manager at Agencia Eremo',
  },
  {
    tag: 'Product Companies',
    content: `If you know Project Huddle, you shouldn’t let it go! ruttl is fast at collecting, managing, and previewing design feedback on live websites. This is a gem, I really love it.`,
    img: '/assets/img/christiano-hans.png',
    author: 'Christiano Hans',
    designation: 'Founder at Hans Republic',
  },
  {
    tag: 'Product Companies',
    content: `I really liked ruttl, easy to use, and I will be using it in the future, either for further developing the page or for other projects. Great product!`,
    img: '/assets/img/eric-halada.jpeg',
    author: 'Eric Halada',
    designation: 'Founder at Eric Halada',
  },
].map(({ tag = '', content, img, author, designation }, id) => ({
  tag,
  content,
  img,
  author,
  designation,
  id,
}));

export const tick = (text) => (
  <div className="flex justify-content-center">
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
      <path
        d="m1 6.2 3.6 3.6L13 1.4"
        stroke="#6552FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <span>{text}</span>
  </div>
);
// const cross = (text) => (
//   <div className="flex">
//     <svg width="18" height="18" fill="none" style={{ marginTop: 2 }}>
//       <path
//         d="M9 17.333A8.333 8.333 0 1 0 9 .667a8.333 8.333 0 0 0 0 16.666Z"
//         fill="#FB4130"
//         fillOpacity="1"
//       />
//       <path
//         d="m11.5 6.5-5 5m0-5 5 5"
//         stroke="#fff"
//         strokeWidth="1.667"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//     <span>{text}</span>
//   </div>
// );

export const pricingTableData = [
  {
    headingType: 'Allowances',
  },
  {
    heading: 'Projects',
    desc: `Add your website, web app or PDF/image files in ruttl's project.`,
    data: {
      free: 3,
      standard: 2,
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },

  {
    heading: 'Users',
    desc: 'Users can add comments and make edits to the projects.',
    data: {
      free: '1',
      standard: 10,
      pro: 'Upto 50',
      team: 'Unlimited',
      enterprise: 'Custom',
    },
  },

  {
    heading: 'Guests',
    desc: 'Guests can view and comment on the projects, without the need of signing up.',
    data: {
      free: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    headingType: 'Project Type',
  },
  {
    heading: 'Websites',
    desc: `Single or set of static landing pages that don't require you to login.`,
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: <span>Web Applications</span>,
    desc: 'Dyanmic in-app pages or dashboard screens with password-gated access. Eg. Twitter, Facebook, Stripe.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: (
      <span className="link-tag" data-value="NEW">
        Video Project
      </span>
    ),
    desc: 'Comment on uploaded or recorded videos, add shape annotations or highlight using the pen tool.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Bug Tracking',
    desc: 'Report issues directly on live product without the need of screenshots. ',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'PDFs',
    desc: 'Leave comments, annotate PDFs and share with your team.',
    data: {
      free: 'File upto 2MB',
      standard: tick(),
      pro: 'File upto 50MB',
      team: 'File upto 100MB',
      enterprise: tick(),
    },
  },
  {
    heading: 'Images',
    desc: 'Upload any static image or multiple versions of them.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    headingType: 'Features',
  },
  {
    heading: 'Comments',
    desc: ' Share feedback quickly with your project collaborators (& clients) through real-time comments.',
    data: {
      free: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    heading: (
      <span className="link-tag" data-value="NEW">
        Private Comments
      </span>
    ),
    desc: 'Add comments, which are only visible to your project collaborators. Private comments cannot be seen by guests/clients.',
    data: {
      free: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    heading: 'Guest Comments',
    desc: 'Ask your clients to leave comments without having to sign-up on ruttl!',
    data: {
      free: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  // {
  //   heading: 'Video Comments',
  //   desc: 'Provide feedback while recording your screen.',
  //   data: {
  //     free: '1 min',
  //     standard: '5 min',
  //     pro: '10 mins',
  //     team: '10 mins',
  //     enterprise: 'Custom',
  //   },
  // },
  {
    heading: 'Attachments in comments',
    desc: 'Attach images, videos, pdf and other files to your comments.',
    data: {
      free: 'upto 1GB',
      standard: 'upto 5GB',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    heading: (
      <span className="link-tag" data-value="NEW">
        15 Device Sizes
      </span>
    ),
    desc: ' Get to see how your site looks like on 15 commonly used devices (Pixel, Samsung, iPhone, iPads, Surface devices & more), with comments and edits separated on each device!',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Edit content',
    desc: 'Content writers can edit website content directly on live website eliminating the need of sharing word documents.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Edit elements',
    desc: 'Edit live website elements and share precise design changes.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Replace Images',
    desc: 'Quickly replace images and view website changes in real-time.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Inspect elements',
    desc: ' Inspect the clean CSS with ease to streamline accessibility issues, redlines and other technical essentials.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    headingType: 'Additional Features',
  },
  {
    heading: (
      <span className="link-tag" data-value="NEW">
        Folders
      </span>
    ),
    desc: 'Create folders to categorise by page types like Blog, Sales, About Us, Marketing, etc.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  // {
  //   heading: (
  //     <span className="link-tag" data-value="NEW">
  //       Embed Link
  //     </span>
  //   ),
  //   desc: 'Make your live web page as ruttl, essentially letting your visitors add comments to your pages in real-time!',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: tick(),
  //     team: tick(),
  //     enterprise: tick(),
  //   },
  // },
  // {
  //   heading: (
  //     <span className="link-tag" data-value="UPCOMING">
  //       Embedded Media
  //     </span>
  //   ),
  //   desc: 'Embed ruttl into your website and view all comments added by your guests.',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: tick(),
  //     team: tick(),
  //     enterprise: tick(),
  //   },
  // },
  {
    heading: 'Versioning',
    desc: 'Add multiple versions to your webpage or image/PDF files, refer to them anytime in the future with ease.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Mobile Responsiveness',
    desc: `Check your website's mobile and web friendiness across different digital devices.`,
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Workspace Storage',
    desc: 'Storage for your video comments, replaced assets and comment attachments.',
    data: {
      free: '5 GB',
      standard: '10 GB',
      pro: '50 GB',
      team: '100 GB',
      enterprise: 'Custom',
    },
  },
  { headingType: 'Integrations' },
  {
    heading: 'Slack',
    desc: 'Get notified of any new comments and edits inside your project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Trello',
    desc: 'Send comments as Trello cards to your preferred Trello board.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Asana',
    desc: 'Automatically send comments to your preferred asana project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Jira',
    desc: 'Automatically send comments to your preferred Jira project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'ClickUp',
    desc: 'Automatically send comments to your preferred ClickUp project.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Custom Webhook',
    desc: `Choose to integrate with 3000+ apps using Zapier.`,
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },

  // {
  //   headingType: 'White Labelling',
  // },
  // {
  //   heading: 'CNAME',
  //   desc: 'Access ruttl via your preferred custom domain.',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: '-',
  //     team: tick(),
  //     enterprise: tick(),
  //   },
  // },
  // {
  //   heading: 'Custom Branding',
  //   desc: 'Customise ruttl’s logo, icon and colours to match with your brand.',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: '-',
  //     team: tick(),
  //     enterprise: tick(),
  //   },
  // },
  {
    headingType: 'Support',
  },
  {
    heading: 'Live customer support',
    desc: ' Reach out to our support agents via live chat for any issues/bugs.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Dedicated success manager',
    desc: 'Receive personalized support resolution from one of our team members.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },

  {
    heading: 'Priority support',
    desc: 'Get quicker dedicated access to our support team to resolve any of your issues on high priority.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
].map(({ headingType = '', heading = '', desc = '', data = [] }, id) => ({
  headingType,
  heading,
  desc,
  data,
  id,
  uuid: nanoid(),
}));

export const bugTrackingPricingTableData = [
  {
    headingType: 'Allowances',
  },

  {
    heading: 'Projects',
    desc: 'Add your websites/web apps inside projects on ruttl.',
    data: {
      free: 'Trial Projects',
      standard: 2,
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    heading: 'Users',
    desc: `Users can add tickets & reply to existing tickets and organise the project's workflow.`,
    data: {
      free: 'Upto 5',
      standard: 10,
      pro: 'Upto 50',
      team: 'Unlimited',
      enterprise: 'Custom',
    },
  },
  {
    heading: 'Guests',
    desc: 'Guests can add tickets without the need of signing up.',
    data: {
      free: 'Unlimited',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },

  {
    headingType: 'Project Type',
  },
  {
    heading: 'Web App Projects',
    desc: `Track bugs on your site or product inside ruttl.`,
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Mobile App Projects',
    desc: `Track bugs directly on your mobile app using ruttl.`,
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    headingType: 'Features',
  },
  {
    heading: 'Add Tickets',
    desc: 'Capture and review bugs on both mobile and web.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Assign Tickets',
    desc: 'Tickets can be assigned within project collaborators to keep a closed feedback loop.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Attachments inside tickets',
    desc: 'To make your feedback more contextual, or to share references.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Assign timelines',
    desc: ' Allot timelines within your project collaborators.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Workflow',
    desc: 'Kanban-styled workflow to organise and manage the tickets better.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },

  {
    heading: 'Video Comments',
    desc: ' Provide feedback while recording your screen.',
    data: {
      free: '1 min',
      standard: '10 mins',
      pro: '10 mins',
      team: '10 mins',
      enterprise: 'Custom',
    },
  },

  {
    heading: 'Workspace Storage',
    desc: 'Storage for your video comments & file attachments inside tickets.',
    data: {
      free: '5 GB',
      standard: '10 GB',
      pro: '50 GB',
      team: '100 GB',
      enterprise: 'Custom',
    },
  },
  { headingType: 'Integrations' },
  {
    heading: 'Slack',
    desc: 'Get notified of any new tickets in your preferred Slack channel.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Trello',
    desc: 'Create Trello cards for your bug-tracking tickets & organise your workflow better.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Asana',
    desc: 'Automate your ticket-sending experience to your preferred asana project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Jira',
    desc: 'Automate your ticket-sending experience to your preferred Jira project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'ClickUp',
    desc: 'Automate your ticket-sending experience to your preferred ClickUp project.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Zapier',
    desc: `Choose to integrate with 3000+ apps using Zapier.`,
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  // { headingType: 'White Labelling' },
  // {
  //   heading: 'CNAME',
  //   desc: 'Run bug-tracking on your preferred domain.',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: 'Coming Soon...',
  //     team: 'Coming Soon...',
  //     enterprise: 'Coming Soon...',
  //   },
  // },
  // {
  //   heading: 'Custom Branding',
  //   desc: `Customise ruttl's logo, icon and colours to match with your brand.`,
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: 'Coming Soon...',
  //     team: 'Coming Soon...',
  //     enterprise: 'Coming Soon...',
  //   },
  // },
  {
    headingType: 'Support',
  },
  {
    heading: 'Live Customer Support',
    desc: ' Reach out to our support agents via live chat for any issues/bugs.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Dedicated success manager',
    desc: 'Receive personalized support resolution from one of our team members.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },

  {
    heading: 'Priority support',
    desc: 'Get quicker dedicated access to our support team to resolve any of your issues on high priority.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
].map(({ headingType = '', heading = '', desc = '', data = [] }, id) => ({
  headingType,
  heading,
  desc,
  data,
  id,
  uuid: nanoid(),
}));

export const customerFeedbackPricingTableData = [
  {
    headingType: 'Allowances',
  },

  {
    heading: 'Projects',
    desc: `Manage your product's OR site's customer feedback inside one project.`,
    data: {
      free: 1,
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },
  {
    heading: 'Messages & File History',
    desc: `Get access to previous conversations.`,
    data: {
      free: '7 days',
      standard: 'Unlimited',
      pro: 'Unlimited',
      team: 'Unlimited',
      enterprise: 'Unlimited',
    },
  },

  {
    heading: 'Users',
    desc: 'Users consist of both agents and members inside your project. Agents chat with your website audience, while members can be from your dev/marketing team.',
    data: {
      free: 'Upto 5',
      standard: 4,
      pro: 'Upto 50',
      team: 'Upto 100',
      enterprise: 'Unlimited',
    },
  },

  { headingType: 'Features' },
  {
    heading: 'Live chat',
    desc: 'Chat with your visitors/users live',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Collect feature requests',
    desc: 'Gather feature requests from your users within the chat interface',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Collect testimonials',
    desc: 'Gather testimonials from your users within the chat interface',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Report Issues',
    desc: 'Collect annotated issues from your users/visitors.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Support Dashboard',
    desc: ' Give your support agents an intuitive dashboard to interact with your users/visitors.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Assign Tickets',
    desc: 'Assign chats or issues to your members with timelines.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  { headingType: 'Customisations' },
  {
    heading: 'Display message',
    desc: 'The initial message displayed to your users when they wish to chat/report issues.',
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Reply time',
    desc: `Customise the reply time shown on your chat widget's header.`,
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Chat avatar',
    desc: `Change the avatar/profile image shown on your chat widget's header.`,
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Away message',
    desc: 'Change the content of the message shown while your support agents are away.',
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: `Collect users' email addresses`,
    desc: `Get the option to collect your users' email addresses.`,
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Chat topics',
    desc: 'Choose between the available chat topics, or bring your own for your users to initiate the chat.',
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Header Colour',
    desc: `Customise the colour of your chat widget's header as per your brand's colours.`,
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Widget',
    desc: `Customise the colour of your chat widget as per your brand's colours.`,
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Icon',
    desc: 'Customise the icon, icon size, shape and position - how the closed chat widget will look like.',
    data: {
      free: '-',
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  { headingType: 'Integrations' },
  {
    heading: 'Slack',
    desc: 'Get notified of any new chats and issues in your preferred Slack channel.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Trello',
    desc: 'Send chats or issues as Trello cards to your preferred Trello board.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Asana',
    desc: 'Automatically send chats or issues to your preferred asana project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Jira',
    desc: 'Automatically send chats or issues to your preferred Jira project.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'ClickUp',
    desc: 'Automatically send chats or issues to your preferred ClickUp project.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Zapier',
    desc: `Choose to integrate with 3000+ apps using Zapier.`,
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  // { headingType: 'White Labelling' },
  // {
  //   heading: 'CNAME',
  //   desc: 'Run customer feedback on your preferred domain.',
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: 'Coming Soon...',
  //     team: 'Coming Soon...',
  //     enterprise: 'Coming Soon...',
  //   },
  // },
  // {
  //   heading: 'Custom Branding',
  //   desc: `Customise ruttl's logo, icon and colours to match with your brand.`,
  //   data: {
  //     free: '-',
  //     standard: '-',
  //     pro: 'Coming Soon...',
  //     team: 'Coming Soon...',
  //     enterprise: 'Coming Soon...',
  //   },
  // },
  {
    headingType: 'Support',
  },
  {
    heading: 'Live Customer Support',
    desc: ' Reach out to our support agents via live chat for any issues/bugs.',
    data: {
      free: tick(),
      standard: tick(),
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
  {
    heading: 'Dedicated success manager',
    desc: 'Receive personalized support resolution from one of our team members.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },

  {
    heading: 'Priority support',
    desc: 'Get quicker dedicated access to our support team to resolve any of your issues on high priority.',
    data: {
      free: '-',
      standard: '-',
      pro: tick(),
      team: tick(),
      enterprise: tick(),
    },
  },
].map(({ headingType = '', heading = '', desc = '', data = [] }, id) => ({
  headingType,
  heading,
  desc,
  data,
  id,
  uuid: nanoid(),
}));

export function getErrorMessage(type, inputName) {
  const genericMessage = 'This input is invalid.';
  let messages;

  switch (type) {
    case 'required':
      return 'This input is required.';

    case 'error': {
      messages = {
        email: 'This is an invalid email.',
        companyEmail: 'Please enter your company email address.',
        phoneNumber: 'This is an invalid mobile number.',
        otp: 'This is an invalid OTP.',
        date: 'This is an invalid date.',
        password: 'Password must contain 8 characters.',
        // password:
        // 'Password must contain 8 characters including at least 1 uppercase, 1 lowercase, 1 digit and 1 special character.',
        confirmPassword: 'Password and Confirm Password must match.',
      };

      return inputName ? messages[inputName] : genericMessage;
    }

    default:
      return genericMessage;
  }
}
export const websiteFeedbackFeatures = [
  {
    cover: '/assets/img/website-feedback-realtime-chat.webp',
    heading: 'Chat live with your team',
    description:
      'Share attachments, reference URLs or bug encounters via real-time chat with your project members.',
  },
  {
    cover: '/assets/img/website-feedback-view-inspect-css.webp',
    heading: 'View & Inspect CSS',
    description:
      'Share precise changes for font size, alignment, margins, paddings and more in real-time with your developers!',
  },
  {
    cover: '/assets/img/website-feedback-integrate-tools.webp',
    heading: 'Integrated with needed tools',
    description:
      'Receive notifications for new tickets through your preferred tools such as Slack, Trello, Asana, ClickUp, Jira or Zapier.',
  },
  {
    cover: '/assets/img/website-feedback-replace-image.webp',
    heading: 'Replace Images',
    description:
      'Quickly replace images and view website changes in real-time.',
  },
  {
    cover: '/assets/img/website-feedback-add-versions.webp',
    heading: 'Add Versions',
    description:
      'Add multiple versions for your files & access them anytime later - including all comments!',
  },
  {
    cover: '/assets/img/website-feedback-drop-comments.webp',
    heading: 'Add Video Comments',
    description: 'Give feedback through textual comments or screen records.',
  },
];

export const BugTrackingFeatures = [
  {
    cover: '/assets/img/drop-comments.png',
    heading: 'Leave text and video comments for more context',
    description: 'Give feedback through textual comments or screen records.',
  },
  {
    cover: '/assets/img/console-network-logs.png',
    heading: 'Get precise console and network logs',
    description:
      'Keep it all clear by seeing the exact viewport dimensions of your bug-reporter.',
  },
  {
    cover: '/assets/img/ticket-assignment.png',
    heading: 'Assign tickets and set deadlines',
    description:
      'Assign precise bug tickets to your developers along with deadlines.',
  },
  {
    cover: '/assets/img/realtime-chat.png',
    heading: 'Chat live with your team',
    description:
      'Share attachments, reference URLs or bug encounters via real-time chat with your project members..',
  },
  {
    cover: '/assets/img/notifications.png',
    heading: 'Stay up to date with notifications',
    description:
      'Get instant emails and notifications for bugs reported within your project.',
  },
  {
    cover: '/assets/img/all-browsers-small .png',
    heading: 'Works on all browsers and on mobile',
    description:
      'Leave comments directly on live websites, make real-time edits to give precise feedbacks to developers.',
  },
];

export const MobileFeedbackFeatures = [
  {
    cover: '/assets/img/drop-comments.png',
    heading: 'Leave text and video comments for more context',
    description: 'Give feedback through textual comments or screen records.',
  },
  {
    cover: '/assets/img/mark-bugs.png',
    heading: 'Use the pen tool to highlight your bugs',
    description:
      'Use the pen tool to mark the bugs and give feedback effortlessly.',
  },
  {
    cover: '/assets/img/ticket-assignment.png',
    heading: 'Assign tickets and set deadlines within your team',
    description:
      'Assign precise bug tickets to your developers along with deadlines.',
  },
  {
    cover: '/assets/img/realtime-chat.png',
    heading: 'Chat live with your team',
    description:
      'Share attachments, reference URLs or bug encounters via real-time chat with your project members..',
  },
  {
    cover: '/assets/img/notifications.png',
    heading: 'Stay up to date with notifications',
    description:
      'Get instant emails and notifications for bugs reported within your project.',
  },
  {
    cover: '/assets/img/device-info.png',
    heading: 'Get Exact device configuration',
    description: `View the mobile device's name & resolution, so that its easier to start working on the exact bug/issue.`,
  },
];

export const pdfImageFeatures = [
  {
    cover: '/assets/img/realtime-chat.png',
    heading: 'Chat live with your team',
    description:
      'Share attachments, or reference URLs via real-time chat with your project members.',
  },
  {
    cover: '/assets/img/notifications.png',
    heading: 'Stay up to date with notifications',
    description:
      'Get instant emails and notifications for all comments added within your project.',
  },
  {
    cover: '/assets/img/integrate-tools.png',
    heading: 'Integrated with needed tools',
    description:
      'Receive notifications for new comments through your preferred tools such as Slack, Trello, Asana, ClickUp, Jira or Zapier.',
  },
  {
    cover: '/assets/img/guest-commenting-feature.png',
    heading: 'Guest commenting',
    description:
      'Share the project with your clients & get comments from them - all without them needing to sign-up!',
  },
  {
    cover: '/assets/img/add-versions.png',
    heading: 'Add Versions',
    description:
      'Add multiple versions for your files & access them anytime later - including all comments!',
  },
  {
    cover: '/assets/img/assign-resolve-comment.png',
    heading: 'Assign & resolve comments',
    description:
      'Assign comments to other project members, discuss & organise your feedback in comment threads.',
  },
];
export const videoAnnotationFeatures = [
  {
    cover: '/assets/img/frame-accurate.png',
    heading: 'Frame-Accurate Feedback',
    description:
      'Mark up video with frame-accurate timestamps, thereby pinpointing with the highest level of detail.',
  },
  {
    cover: '/assets/img/record-your-video.png',
    heading: 'Record your video',
    description:
      'Record videos directly inside ruttl. Choose to share your camera while recording your screen as well.',
  },
  {
    cover: '/assets/img/assign-resolve-comment.png',
    heading: 'Assign & resolve comments',
    description:
      'Assign comments to other project members, discuss & organise your feedback in comment threads.',
  },
  {
    cover: '/assets/img/realtime-chat.png',
    heading: 'Chat live with your team',
    description:
      'Share attachments, or reference URLs via real-time chat with your project members.',
  },
  {
    cover: '/assets/img/notifications.png',
    heading: 'Stay up to date with notifications',
    description:
      'Get instant emails and notifications for all comments added within your project.',
  },
  {
    cover: '/assets/img/integrate-tools.png',
    heading: 'Integrated with needed tools',
    description:
      'Receive notifications for new comments through your preferred tools such as Slack, Trello, Asana, ClickUp, Jira or Zapier.',
  },
];

export const BuiltForTeamData = [
  {
    tag: 'Agencies',
    points: [
      'Gather contextual feedbacks from clients',
      'Resolve comments and keep a track on project progress',
      'Make edits to visualise changes',
      'Share video comments to developers to speed up the process',
    ],
  },
  {
    tag: 'Product Teams',
    points: [
      'Share video comments to developers to speed up the process',
      'Gather contextual feedbacks from clients',
      'Resolve comments and keep a track on project progress',
      'Make edits to visualise changes',
    ],
  },
  {
    tag: 'Marketing Teams',
    points: [
      'Make edits to visualise changes',
      'Gather contextual feedbacks from clients',
      'Resolve comments and keep a track on project progress',
      'Share video comments to developers to speed up the process',
    ],
  },
  {
    tag: 'QA Teams',
    points: [
      'Gather contextual feedbacks from clients',
      'Resolve comments and keep a track on project progress',
      'Make edits to visualise changes',
      'Share video comments to developers to speed up the process',
    ],
  },
].map(({ tag = '', points = [] }) => ({
  tag,
  points,
}));

export const productsData = [
  {
    name: 'Website Feedback Tool',
    position: 1,
    desc: 'Leave comments directly on live websites and make real time edits to give precise feedbacks to developers. So no more screenshots for sending feedbacks to team members.',
    image: '/assets/img/website-feedback-home.png',
  },
  {
    name: 'Bug Tracking',
    position: 2,
    desc: 'Leave comments directly on live websites and make real time edits to give precise feedbacks to developers. So no more screenshots for sending feedbacks to team members.',
    image: '/assets/img/bugtracking-home.png',
  },
  {
    name: 'PDF/Image Review',
    position: 3,
    desc: 'Leave comments directly on live websites and make real time edits to give precise feedbacks to developers. So no more screenshots for sending feedbacks to team members.',
    image: '/assets/img/pdf-image-home.png',
  },
];
